body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F6F6F8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Neue montreal';
    src: url('./NeueMontreal-Regular.ttf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Neue montreal';
    src: url('./NeueMontreal-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}