.wrapp-form-asesorias {
    padding: 72px 90px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 42px;
    width: 100%;
}

.head-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
}

.form-asesorias {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0px;
    max-width: 950px;
}

.wrapp-each-item-form {
    margin-top: 14px;
    margin-bottom: 40px;
}

.text-head-group-radio {
    font-family: 'Neue montreal';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color: #3C4549;
    letter-spacing: 2%;
}

.info-text {
    font-family: 'Neue montreal';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #757575;
}

.info-text-red {
    font-family: 'Neue montreal';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #C60E02;
    margin-top: -30px;
}

.info-text-strong {
    font-family: 'Neue montreal';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #3C4549;
}

.wrapp-text-alert-img-upload {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.text-radio label {
    font-family: 'Neue montreal';
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #3C4549;
    letter-spacing: 2%;
    width: 210px;
}

.text-radio {
    font-family: 'Neue montreal';
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #3C4549;
    letter-spacing: 2%;
}

.none-div {
    display: none!important;
}

.wrapp-radio-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.head-master-form {
    font-family: 'Neue montreal';
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    color: #3C4549;
    letter-spacing: 2%;
    margin: 0;
}

.wrapp-upload-content {
    margin-top: 18px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 24px;
    width: auto;
    margin-bottom: 40px;
}

.alert-upload-img {
    padding: 9px 16px;
    background: #EEEEF2;
    border-style: solid;
    border-width: 1px;
    border-color: #9E9E9E;
    border-radius: 8px;
    display: flex;
    gap: 4.5px;
    width: 440px;
    margin-top: 8px;
}

.titlePopup {
    font-family: 'Neue montreal';
    font-size: 24px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
}

.textpopup {
    font-family: 'Neue montreal';
    font-size: 18px!important;
    font-weight: 400!important;
    margin: 0px;
    padding: 0px;
    text-align: left!important;
}

.textpopup span {
    font-weight: 500!important;
}

.confirmBtn {
    border-radius: 30px!important;
    padding: 12px 25px;
    background: #EB9542!important;
    color: #fff!important;
    font-family: 'Neue montreal';
    font-size: 18px!important;
    font-weight: 400!important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: none!important;
}

.btn-siguiente {
    padding: 12px 25px;
    background: #EB9542;
    border-radius: 30px;
    font-size: 18px;
    height: auto;
    line-height: 22px;
}

.btn-siguiente:hover {
    background: #f19c4b!important;
}

.btn-siguiente:disabled {
    background: #ffbf84!important;
    color: #fff;
}

input {
    font-family: 'Neue montreal'!important;
    font-size: 18px!important;
    font-weight: 400!important;
    color: #3C4549!important;
}

textarea {
    font-family: 'Neue montreal'!important;
    font-size: 18px!important;
    font-weight: 400!important;
    color: #3C4549!important;
}

.input-text {
    height: 49px;
    width: 210px;
}


/*Calendly*/

.calendly-inline-widget {
    height: 900px!important;
    width: 100%!important;
}

div:where(.swal2-container) button:where(.swal2-close):focus {
    box-shadow: none!important;
}

.popAlert {
    width: 600px;
    border-radius: 30px;
}

.backdropPopup {
    background: #000;
}

.testss {
    background: #fff;
}

.textpopup-pay {
    font-family: 'Neue montreal';
    font-size: 18px!important;
    font-weight: 400!important;
    margin: 0px;
    padding: 0px;
    text-align: left!important;
    color: #757575;
}

.textpopup-pay li {
    margin-bottom: 16px;
}

.textpopup-pay span {
    color: #C60E02;
    font-weight: 500!important;
}

.textpopup-pay strong {
    color: #595959;
    font-weight: 500!important;
}

html,
.scroll-container {
    overflow: scroll;
}

html,
.scroll-container {
    scroll-behavior: smooth;
}

.wrapRadioBtns {
    width: 450px;
    margin-bottom: 0px;
}

@media (prefers-reduced-motion) {
    html,
    .scroll-container {
        scroll-behavior: auto;
    }
}

@media only screen and (max-width: 600px) {
    .wrapp-all-content {
        flex-direction: column;
    }
    .wrapp-side-bar {
        width: 100%;
    }
    .wrapp-side-bar {
        width: auto;
    }
    .wrapp-form-asesorias {
        padding: 48px 24px;
    }
    .wrapRadioBtns {
        width: 100%;
        margin-bottom: 0px;
    }
    .alert-upload-img {
        width: auto;
    }
    .wrapp-form-asesorias {
        width: auto;
    }
    .text-radio {
        display: flex;
        gap: 16px;
        flex-direction: column;
    }
}