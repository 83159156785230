.App {
    text-align: center;
}

p {
    margin: 0px;
}

ul {
    margin: 0px;
}

.header-b360 {
    padding: 20px 0px;
    background: rgb(227, 119, 45);
    background: linear-gradient(90deg, rgba(227, 119, 45, 1) 0%, rgba(242, 181, 89, 1) 100%);
}

.logo-app {
    width: 70px;
}

.wrapp-all-content {
    display: flex;
    flex-direction: row;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}